<template>
  <div class="row justify-content-center align-content-center">
    <b-container class="bv-example-row">
      <b-row>
        <b-col v-for="group in uniqueGroupsList(routes)">
          <h2>{{ group }}</h2>
          <div v-for="route in filterRoutesByGroup(group, routes)">
            <a class="button" :href="route.entrypoint">{{ route.title }}</a>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {
  ArrowUpIcon,
  VideoIcon
} from 'vue-feather-icons';
export default {
  data() {
    return {
      routes:[],
      navMode: process.env.VUE_APP_NAVIGATION_MODE,
      showAccountConfirmAlert: 'no',
      filteredGroups: [],
      filteredRoutes: [],
    }
  },
  methods: {
    filterRoutesByGroup(group, routes) {
      return routes.filter((x) => (x.group == group))
    },
    uniqueGroupsList(routes) {
      let unique = [...new Set(routes.map((x) => (x.group)))];
      return unique
    },
  },
  beforeMount() {

    this.showAccountConfirmAlert = this.$cookies.get('showAccountConfirmAlert');
    this.axios({
      method: 'post',
      url: '' + this.$hostname_auth + '/auth/get_frontend_routes',
      data: { 'user_id': this.$cookies.get('id_sml') }
    }).then((res)=>{
      this.routes = res.data;

    }
    )
  },
  components: {
    ArrowUpIcon,
    VideoIcon,
  }
}
</script>

<style>
.modal-content {
  background-color: transparent;
}
</style>
